import { React, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Fingerprint from '@mui/icons-material/Fingerprint'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
export default function SearchBar (props) {
  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        console.log('Enter key was pressed. Run your function.')
        event.preventDefault()
        props.generateTattoos()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [props])
  function optionalTattooInputs () {
    const handleChange = event => {
      props.setStyle(event.target.value)
    }

    return (
      <div className='Dropdown'>
        <FormControl sx={{ minWidth: 'auto' }}>
          <InputLabel id='demo-simple-select-label'>Tattoo Style</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={props.selectedStyle}
            label='Tattoo Style'
            onChange={handleChange}
          >
            <MenuItem value={'Traditional'}>Traditional</MenuItem>
            <MenuItem value={'Neo-Traditional'}>Neo-Traditional</MenuItem>
            <MenuItem value={'Fine Line'}>Fine Line</MenuItem>
            <MenuItem value={'Tribal'}>Tribal</MenuItem>
            <MenuItem value={'Watercolor'}>Watercolor</MenuItem>
            <MenuItem value={'Blackwork'}>Blackwork</MenuItem>
            <MenuItem value={'Realism'}>Realism</MenuItem>
            <MenuItem value={'Japanese'}>Japanese</MenuItem>
            <MenuItem value={'Trash Polka'}>Trash Polka</MenuItem>
            <MenuItem value={'Geometric'}>Geometric</MenuItem>
            <MenuItem value={'Black and Grey'}>Black and Grey</MenuItem>
            <MenuItem value={'Anime'}>Anime</MenuItem>
            <MenuItem value={'Aesthetic'}>Aesthetic</MenuItem>
            <MenuItem value={'Abstract'}>Abstract</MenuItem>
            <MenuItem value={'3D'}>3D</MenuItem>
            <MenuItem value={'Cartoon'}>Cartoon</MenuItem>
            <MenuItem value={'Continuous Line Contour'}>
              Continuous Line Contour
            </MenuItem>
            <MenuItem value={'Sketch'}>Sketch</MenuItem>
          </Select>
        </FormControl>
      </div>
    )
  }
  return (
    <Grid container>
      <Grid item xs={10}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
              m: 1
            }
          }}
        >
          {optionalTattooInputs()}
        </Box>
      </Grid>
      <Grid item xs={11}>
        <TextField
          onChange={e => {
            console.log(e)
            props.setSearch(e.target.value)
          }}
          fullWidth
          label='Describe Your Idea'
          id='fullWidth'
          color='secondary'
        />
      </Grid>
      <Grid item alignItems='stretch' style={{ display: 'flex' }} xs={1}>
        {loadingDisplay(props)}
      </Grid>
    </Grid>
  )
}

function loadingDisplay (props) {
  if (props.isLoading) {
    return <CircularProgress color='secondary' />
  } else {
    return (
      <Tooltip title='Generate'>
        <IconButton
          aria-label='fingerprint'
          color='secondary'
          onClick={props.generateTattoos}
        >
          <Fingerprint />
        </IconButton>
      </Tooltip>
    )
  }
}
