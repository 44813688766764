import './App.css'
import ResponsiveAppBar from './components/AppBar'
import SearchBar from './components/SearchBar'
import TattoosDisplay from './components/TattoosDisplay'
import { useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

function App () {
  const [tattoos, setTattoos] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [err, setErr] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedStyle, setStyle] = useState('Traditional')

  return (
    <>
      <div>
        <ResponsiveAppBar />
      </div>
      <div className='Search-Bar'>
        <SearchBar
          search={search}
          setSearch={setSearch}
          generateTattoos={generateTattoos}
          isLoading={isLoading}
          setStyle={setStyle}
          selectedStyle={selectedStyle}
        />
        {err && (
          <Snackbar
            open={err}
            autoHideDuration={6000}
            onClose={() => setErr(false)}
          >
            <MuiAlert severity='error' sx={{ width: '100%' }}>
              There was a problem, please try your tattoo generation again
            </MuiAlert>
          </Snackbar>
        )}
        {isLoading && (
          <Snackbar
            open={isLoading}
            autoHideDuration={10000}
            onClose={() => setErr(false)}
          >
            <MuiAlert severity='info' sx={{ width: '100%' }}>
              It takes between 1 to 2 minutes to generate images
            </MuiAlert>
          </Snackbar>
        )}
      </div>
      <div className='Tattoo-Display'>
        <TattoosDisplay tattoos={tattoos} isLoading={isLoading} />
      </div>
      <div className='Tattoo-Display'>
        <p>Images are created with craiyon.ai</p>
      </div>
    </>
  )
  async function generateTattoos () {
    setIsLoading(true)
    try {
      const response = await fetch(
        'https://generate-tattoos-v2nt6ivn2q-uc.a.run.app',
        {
          method: 'POST',
          body: JSON.stringify({
            message: formatSearchString(search, selectedStyle)
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )

      if (!response.ok) {
        setErr(true)
      }

      const result = await response.json()

      setTattoos(result.images)
    } catch (err) {
      setErr(true)
    } finally {
      setIsLoading(false)
    }
  }
}
function formatSearchString (search, selectedStyle) {
  return selectedStyle + ' style tattoo of ' + search
}
export default App
