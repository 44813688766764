import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Skeleton from '@mui/material/Skeleton'
export default function TattoosDisplay (props) {
  if (props.isLoading) {
    return loadingDisplay('wave')
  } else if (props.tattoos.length === 0) {
    return loadingDisplay(false)
  } else {
    return finishedLoadingDisplay(props)
  }
}
function loadingDisplay (animation) {
  return (
    <>
      <ImageList cols={3} rowHeight='auto' gap={1}>
        {[...new Array(9)].map((item, index) => (
          <ImageListItem key={index}>
            <Skeleton
              variant='rectangular'
              width='auto'
              height='auto'
              animation={animation}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  )
}

function finishedLoadingDisplay (props) {
  return (
    <>
      <ImageList cols={3} rowHeight='auto' gap={1}>
        {props.tattoos.map((item, index) => (
          <ImageListItem key={index}>
            <img src={`data:image/png;base64,${item}`} alt='tattoo' />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  )
}
